<template>
  <b-container
      class="bv-example-row mb-3"
      fluid
  >
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-card-actions
          v-if="!filtre"
          ref="refreshCard"
          class="cardActions"
          action-refresh
          @refresh="refreshStop('refreshCard')"
      >
        <b-row class="mt-50 mb-3">
          <div class="card-header-row-btn" :style="{backgroundColor: $store.getters.cardTitle}">
            <b-col cols="12">
              <h4 class="text-white d-inline-block"> {{
                  baslangic
                      ? baslangic + ' ile ' + bitis + ' tarih aralığındaki kayıtlar'
                      : 'Online Rezervasyon Listesi'
                }}</h4>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-print="'#printMe'"
                  variant="bgInfo"
                  class="border-0 float-right"
                  :style="{backgroundColor:$store.getters.cardTitleButton}"
              >
                <feather-icon
                    icon="PrinterIcon"
                    class="mr-50"
                />
                Listeyi Yazdır
              </b-button>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="myButton mr-50 border-0 float-right"
                  :style="{backgroundColor:$store.getters.cardTitleButton}"
                  @click="refreshStop('refreshCard')"
              >
                <feather-icon
                    icon="RefreshCcwIcon"
                    class="mr-50"
                />
                Sıfırla
              </b-button>
            </b-col>
          </div>
        </b-row>
        <hr>
        <b-row :class="width < 610 ?'mt-1':''">
          <b-col cols="12" :class="width < 803 ? 'mt-4':''">
            <b-input-group class="float-right flat-date justify-content-end w-100">
              <b-input-group-prepend>
                <flat-pickr
                    v-model="baslangic"
                    class="form-control"
                />
              </b-input-group-prepend>
              <b-button
                  variant="outline-primary"
                  disabled
                  class="disabledColor"
              >
                <feather-icon icon="CalendarIcon"/>
              </b-button>
              <b-input-group-append>
                <flat-pickr
                    v-model="bitis"
                    class="form-control mr-2"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="12">
            <b-tabs
                v-if="!filtre"
                id="printMe"
            >
              <b-tab
                  active
                  title="Aktif Konaklamalar"
              >
                <b-row>
                  <b-col
                      lg="6"
                      md="6"
                      sm="12"
                      cols="12"
                      class="my-1"
                  >
                    <b-input-group>
                      <b-input-group
                          size="sm"
                          prepend="Gösterilecek kayıt sayısı"
                          class="unPrint"
                      >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                            @change="perPageChange($event)"
                        />
                      </b-input-group>
                    </b-input-group>
                  </b-col>
                  <b-col
                      lg="3"
                      md="3"
                      sm="12"
                      cols="12"
                      class="my-1 d-flex justify-content-end"
                  >
                    <b-input-group
                        size="sm"
                        class="searchGroup"
                    >
                      <b-input-group-prepend>
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            disabled
                            class="araButton"
                        >
                          Ara
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                      />
                    </b-input-group>
                  </b-col>
                </b-row>
                <b-table
                    hover
                    striped
                    empty-text=""
                    :items="salaryListItems"
                    :fields="fields"
                    :per-page="perPage"
                    :filter-included-fields="filterOn"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    stacked="md"
                    show-empty
                    small
                    @filtered="onFiltered"
                    @row-clicked="pushRezervasyon"
                >
                  <template #cell(odaKodu)="data">
                    {{ data.item.odaKodu }}
                  </template>
                  <template #cell(rezervasyonNo)="data">
                    <a @click="rezGo(data.item.rezervasyonNo)">{{
                        data.item.rezervasyonNo
                      }}</a>
                  </template>
                  <template #cell(tarife)="data">
                    {{ tarifeGet(data.item.tarife) }}
                  </template>
                  <template #cell(adi)="data">
                    {{ data.item.adi }} {{ data.item.soyAdi }}
                  </template>
                  <template #cell(kayitTarih)="data">
                    {{ data.item.kayitTarih.slice(0, -8) }}
                  </template>
                  <template #cell(cikisTarih)="data">
                    {{ data.item.cikisTarih }}
                  </template>
                  <template #cell(checkOut)="data">
                    <b-badge
                        pill
                        :variant="data.item.checkOut == 1 ? 'bgDanger' : 'bgInfo'"
                    >
                      <strong>{{
                          data.item.checkOut == 1
                              ? 'Çıkış yapıldı'
                              : 'Aktif Konaklama'
                        }}</strong>
                    </b-badge>
                  </template>
                </b-table>
                <hr>
                <b-row class="alignItemsCenter mt-1">
                  <b-col cols="6">
                    <p class="text-muted">
                      Toplam {{ todayToplamKayit }} Kayıt Bulundu
                    </p>
                  </b-col>
                  <div class="demo-spacing-0">
                    <b-col cols="6">
                      <b-pagination
                          v-model="currentPage"
                          :align="'rigth'"
                          :per-page="perPage"
                          :total-rows="todayToplamKayit"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          @change="currentPageChange($event)"
                      >
                        <template #prev-text>
                          <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                          />
                        </template>
                      </b-pagination>
                    </b-col>
                  </div>
                </b-row>
              </b-tab>
              <b-tab title="Gelecek Konaklamalar">
                <b-row>
                  <b-col
                      sm="3"
                      md="3"
                      class="my-1"
                  >
                    <b-input-group>
                      <b-input-group
                          size="sm"
                          prepend="Gösterilecek kayıt sayısı"
                      >
                        <b-form-select
                            id="per-page-select"
                            v-model="TperPage"
                            :options="pageOptionsT"
                            size="sm"
                            @change="perPageChangeT($event)"
                        />
                      </b-input-group>
                    </b-input-group>
                  </b-col>
                  <b-col
                      lg="3"
                      class="my-1 d-flex justify-content-end"
                  >
                    <b-input-group
                        size="sm"
                        class="searchGroup"
                    >
                      <b-input-group-prepend>
                        <b-button
                            variant="outline-primary"
                            size="sm"
                            disabled
                            class="araButton"
                        >
                          Ara
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input
                          id="filter-input"
                          v-model="filterT"
                          type="search"
                      />
                    </b-input-group>
                  </b-col>
                  <b-table
                      hover
                      striped
                      empty-text=""
                      :items="tSalaryListItems"
                      :fields="fields"
                      :per-page="TperPage"
                      :filter-included-fields="filterOn"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      stacked="md"
                      show-empty
                      small
                      @filtered="onFiltered"
                      @row-clicked="pushRezervasyon"
                  >
                    <template #cell(odaKodu)="data">
                      {{ data.item.odaKodu }}
                    </template>
                    <template #cell(rezervasyonNo)="data">
                      <a @click="rezGo(data.item.rezervasyonNo)">{{
                          data.item.rezervasyonNo
                        }}</a>
                    </template>
                    <template #cell(tarife)="data">
                      {{ tarifeGet(data.item.tarife) }}
                    </template>
                    <template #cell(adi)="data">
                      {{ data.item.adi }} {{ data.item.soyAdi }}
                    </template>
                    <template #cell(kayitTarih)="data">
                      {{ data.item.kayitTarih.slice(0, -8) }}
                    </template>
                    <template #cell(cikisTarih)="data">
                      {{ data.item.cikisTarih }}
                    </template>
                    <template #cell(checkOut)="data">
                      <b-badge
                          pill
                          :variant="data.item.checkOut == 1 ? 'bgDanger' : 'bgInfo'"
                      >
                        <strong>{{
                            data.item.checkOut == 1
                                ? 'Çıkış yapıldı'
                                : 'Aktif Konaklama'
                          }}</strong>
                      </b-badge>
                    </template>
                  </b-table>
                  <hr>
                </b-row>
                <hr>
                <b-row>
                  <b-col cols="6">
                    <p class="text-muted">
                      Toplam {{ TtodayToplamKayit }} Kayıt Bulundu
                    </p>
                  </b-col>
                  <div class="demo-spacing-0">
                    <b-col
                        cols="6"
                        class=""
                    >
                      <b-pagination
                          v-model="TcurrentPage"
                          :align="'rigth'"
                          :per-page="TperPage"
                          :total-rows="TtodayToplamKayit"
                          first-number
                          last-number
                          prev-class="prev-item"
                          next-class="next-item"
                          @change="TcurrentPageChange($event)"
                      >
                        <template #prev-text>
                          <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                          />
                        </template>
                      </b-pagination>
                    </b-col>
                  </div>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-card-actions>
      <b-card-actions
          v-if="filtre"
          id="printMe"
          ref="refreshCard"
          class="cardActions"
          action-refresh
          @refresh="refreshStop('refreshCard')"
      >
        <b-row class="mt-50 mb-3">
          <div class="card-header-row-btn" :style="{backgroundColor: $store.getters.cardTitle}">
            <b-col cols="12">
              <h4 class="text-white d-inline-block"> {{
                  baslangic
                      ? baslangic + ' ile ' + bitis + ' tarih aralığındaki kayıtlar'
                      : 'Online Rezervasyon Listesi'
                }}</h4>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-print="'#printMe'"
                  variant="bgInfo"
                  class="float-right border-0"
                  :style="{backgroundColor:$store.getters.cardTitleButton}"
              >
                <feather-icon
                    icon="PrinterIcon"
                    class="mr-50"
                />
                Listeyi Yazdır
              </b-button>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="myButton mr-1 border-0 float-right"
                  @click="refreshStop('refreshCard')"
                  :style="{backgroundColor:$store.getters.cardTitleButton}"
              >
                <feather-icon
                    icon="RefreshCcwIcon"
                    class="mr-50"
                />
                Sıfırla
              </b-button>
            </b-col>
          </div>
        </b-row>
        <hr class="mt-4 mb-0" :class="width < 610 ?'mt-5':''">
        <b-row>
          <b-col
              cols="12"
              class="my-1"
              :class="width < 803 ? 'mt-4':''"
          >
            <b-input-group class="justify-content-end">
              <b-input-group-prepend>
                <flat-pickr
                    v-model="baslangic"
                    class="form-control"
                />
              </b-input-group-prepend>
              <b-button
                  variant="outline-primary"
                  disabled
                  class="disabledColor"
              >
                <feather-icon icon="CalendarIcon"/>
              </b-button>
              <b-input-group-append>
                <flat-pickr
                    v-model="bitis"
                    class="form-control"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col
              lg="6"
              md="6"
              sm="12"
              cols="12"
              class="my-1"
          >
            <b-input-group>
              <b-input-group
                  size="sm"
                  prepend="Gösterilecek kayıt sayısı"
              >
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                    @change="perPageChange($event)"
                />
              </b-input-group>
            </b-input-group>
          </b-col>
          <b-col
              lg="6"
              md="6"
              sm="12"
              cols="12"
              class="my-1 d-flex justify-content-end"
          >
            <b-input-group
                size="sm"
                class="searchGroup"
            >
              <b-input-group-prepend>
                <b-button
                    variant="outline-primary"
                    size="sm"
                    disabled
                    class="araButton"
                >
                  Ara
                </b-button>
              </b-input-group-prepend>
              <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
              />
            </b-input-group>
          </b-col>
        </b-row>
        <b-table
            hover
            striped
            empty-text="Kayıtlı Veri Yok"
            :items="salaryListItemsFiltre"
            :fields="fields"
            :per-page="perPage"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            stacked="md"
            show-empty
            small
            @filtered="onFiltered"
            @row-clicked="pushRezervasyon"
        >
          <template #cell(odaKodu)="data">
            {{ data.item.odaKodu }}
          </template>
          <template #cell(rezervasyonNo)="data">
            <a @click="rezGo(data.item.rezervasyonNo)">{{
                data.item.rezervasyonNo
              }}</a>
          </template>
          <template #cell(tarife)="data">
            {{ tarifeGet(data.item.tarife) }}
          </template>
          <template #cell(adi)="data">
            {{ data.item.adi }} {{ data.item.soyAdi }}
          </template>
          <template #cell(kayitTarih)="data">
            {{ data.item.kayitTarih.slice(0, -8) }}
          </template>
          <template #cell(cikisTarih)="data">
            {{ data.item.cikisTarih }}
          </template>
          <template #cell(checkOut)="data">
            <b-badge
                pill
                :variant="data.item.checkOut == 1 ? 'bgDanger' : 'bgInfo'"
            >
              <strong>{{
                  data.item.checkOut == 1 ? 'Çıkış yapıldı' : 'Aktif Konaklama'
                }}</strong>
            </b-badge>
          </template>
        </b-table>
        <hr>
        <b-row class="alignItemsCenter">
          <b-col cols="6">
            <p class="text-muted">
              Toplam {{ todayToplamKayit }} Kayıt Bulundu
            </p>
          </b-col>
          <div class="demo-spacing-0">
            <b-col
                cols="6"
                class=""
            >
              <b-pagination
                  v-model="currentPage"
                  :align="'rigth'"
                  :per-page="perPage"
                  :total-rows="todayToplamKayit"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  @change="currentPageChange($event)"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </div>
        </b-row>
      </b-card-actions>
    </div>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BTabs,
  BTab,
  BBreadcrumb,
  BButtonGroup,
  BFormInput,
  BInputGroup,
  BSpinner,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckboxGroup,
  BFormCheckbox,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { useWindowSize } from '@vueuse/core'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr

flatpickr.localize(Turkish)
flatpickr(flatPickr)
export default {
  components: {
    BCardActions,
    BInputGroupPrepend,
    BRow,
    BCol,
    BContainer,
    BTable,
    BTabs,
    BTab,
    BBadge,
    BForm,
    BButton,
    BCard,
    BBreadcrumb,
    BButtonGroup,
    BFormInput,
    BInputGroup,
    BSpinner,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BFormCheckboxGroup,
    BFormCheckbox,
    BModal,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      spinner: true,
      todayToplamKayit: '',
      TtodayToplamKayit: '',
      filtre: false,
      islem: false,
      baslangic: '',
      bitis: '',
      fields: [
        {
          key: 'odaKodu',
          label: 'ODA',
          sortable: true,
          sortDirection: 'desc',
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'rezervasyonNo',
          label: 'REZERVASYON NO',
          class: 'text-center',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'tarife',
          label: 'TARİFE',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'adi',
          label: 'KONUK İSMİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'girisTarih',
          label: 'GİRİŞ TARİHİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'cikisTarih',
          label: 'ÇIKIŞ TARİHİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'checkOut',
          label: 'DURUM',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
      ],
      totalRows: 1,
      TtotalRows: 1,
      currentPage: 1,
      TcurrentPage: 1,
      perPage: 20,
      TperPage: 20,
      pageOptions: [5, 10, 15, 20, 25, 50, 100],
      pageOptionsT: [5, 10, 15, 20, 25, 50, 100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      filterT: null,
      filterOnT: [],
    }
  },
  computed: {
    salaryListItems() {
      if (this.$store.getters.onlineRezActiveList) {
        return this.$store.getters.onlineRezActiveList
      }
    },
    tSalaryListItems() {
      if (this.$store.getters.onlineRezGelecekList) {
        return this.$store.getters.onlineRezGelecekList
      }
    },
    salaryListItemsFiltre() {
      if (this.$store.getters.onlineRezFiltreList) {
        return this.$store.getters.onlineRezFiltreList
      }
    },
  },
  watch: {
    baslangic(newVal) {
      if (newVal) {
        this.currentPage = 1
        this.filtre = true
        this.islem = true
        const veri = newVal * this.perPage - this.perPage
        this.$refs.refreshCard.showLoading = true
        const today = new Date()
        const yil = today.getFullYear()
        const gun = String(today.getDate())
            .padStart(2, '0')
        const ay = String(today.getMonth() + 1)
            .padStart(2, '0')
        const mevcutGun = `${yil}-${ay}-${gun}`
        this.$store
            .dispatch('onlineRezListAktive', {
              start: newVal,
              end: this.bitis || mevcutGun,
              uzunluk: this.perPage,
              baslangic: veri,
              searchKey: this.filter,
              filtre: true,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.todayToplamKayit = res.arsivSayac
              this.totalRows = res.arsivSayac.length
            })
        if (!this.bitis) {
          this.bitis = mevcutGun
        }
      }
    },
    bitis(newVal) {
      if (newVal) {
        this.currentPage = 1
        this.filtre = true
        this.islem = true
        const veri = newVal * this.perPage - this.perPage
        this.$refs.refreshCard.showLoading = true
        const today = new Date()
        const yil = today.getFullYear()
        const gun = String(today.getDate())
            .padStart(2, '0')
        const ay = String(today.getMonth() + 1)
            .padStart(2, '0')
        const mevcutGun = `${yil}-${ay}-${gun}`
        this.$store
            .dispatch('onlineRezListAktive', {
              start: this.baslangic || mevcutGun,
              end: newVal,
              uzunluk: this.perPage,
              baslangic: veri,
              searchKey: this.filter,
              filtre: true,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.todayToplamKayit = res.arsivSayac
              this.totalRows = res.arsivSayac.length
              // this.totalRows = this.$store.getters.posFaturaReturn.toplamKayit
            })
        if (!this.baslangic) {
          this.baslangic = mevcutGun
        }
      }
    },
    filter(newVal) {
      if (this.baslangic && this.bitis) {
        this.islem = true
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('onlineRezListAktive', {
              uzunluk: this.perPage,
              baslangic: 0,
              searchKey: newVal,
              start: this.baslangic,
              end: this.bitis,
              filtre: true,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.todayToplamKayit = res.arsivSayac
              this.totalRows = res.arsivSayac.length
            })
      } else {
        this.islem = true
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('onlineRezListAktive', {
              uzunluk: this.perPage,
              baslangic: 0,
              searchKey: newVal,
              filtre: false,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.todayToplamKayit = res.aktifKonuklarSayac
              this.totalRows = res.aktifKonuklar.length
            })
      }
    },
    filterT(newVal) {
      if (this.baslangic && this.bitis) {
        this.islem = true
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('onlineRezListGelecek', {
              uzunluk: this.TperPage,
              baslangic: 0,
              searchKey: newVal,
              start: this.baslangic,
              end: this.bitis,
              filtre: true,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.todayToplamKayit = res.arsivSayac
              this.totalRows = res.arsivSayac.length
            })
      } else {
        this.islem = true
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('onlineRezListGelecek', {
              uzunluk: this.TperPage,
              baslangic: 0,
              searchKey: newVal,
              filtre: false,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.TtodayToplamKayit = res.gelecekKonuklarSayac
              this.TtotalRows = res.gelecekKonuklar.length
            })
      }
    },
  },
  created() {
    this.islem = true
    this.$store
        .dispatch('onlineRezListAktive', {
          uzunluk: this.perPage,
          baslangic: 0,
        })
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          this.islem = false
          this.todayToplamKayit = res.aktifKonuklarSayac
          this.totalRows = res.aktifKonuklar.length
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
    this.$store
        .dispatch('onlineRezListGelecek', {
          uzunluk: this.perPage,
          baslangic: 0,
        })
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.islem = false
          this.TtodayToplamKayit = res.gelecekKonuklarSayac
          this.TtotalRows = res.gelecekKonuklar.length
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearPosFatura')
  },
  methods: {
    pushRezervasyon(item) {
      this.$router.push({
        name: 'rezervasyon',
        params: { id: item.rezervasyonNo },
      })
    },
    currentPageChange(newVal) {
      if (this.baslangic && this.bitis) {
        const veri = newVal * this.perPage - this.perPage
        const today = new Date()
        const yil = today.getFullYear()
        const gun = String(today.getDate())
            .padStart(2, '0')
        const ay = String(today.getMonth() + 1)
            .padStart(2, '0')
        const mevcutGun = `${yil}-${ay}-${gun}`
        this.islem = true
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('onlineRezListAktive', {
              uzunluk: this.perPage,
              baslangic: veri,
              searchKey: this.filter,
              start: this.baslangic || mevcutGun,
              end: this.bitis || mevcutGun,
              filtre: true,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.todayToplamKayit = res.arsivSayac
              this.totalRows = res.arsiv.length
            })
      } else {
        const veri = newVal * this.perPage - this.perPage
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('onlineRezListAktive', {
              uzunluk: this.perPage,
              baslangic: veri,
              searchKey: this.filter,
              filtre: false,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
            })
      }
    },
    TcurrentPageChange(newVal) {
      if (this.baslangic && this.bitis) {
        const veri = newVal * this.perPage - this.perPage
        const today = new Date()
        const yil = today.getFullYear()
        const gun = String(today.getDate())
            .padStart(2, '0')
        const ay = String(today.getMonth() + 1)
            .padStart(2, '0')
        const mevcutGun = `${yil}-${ay}-${gun}`
        this.islem = true
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('onlineRezListGelecek', {
              uzunluk: this.perPage,
              baslangic: veri,
              searchKey: this.filter,
              start: this.baslangic || mevcutGun,
              end: this.bitis || mevcutGun,
              filtre: true,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.todayToplamKayit = res.arsivSayac
              this.totalRows = res.arsiv.length
            })
      } else {
        const veri = newVal * this.TperPage - this.TperPage
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('onlineRezListGelecek', {
              uzunluk: this.TperPage,
              baslangic: veri,
              searchKey: this.filterT,
              filtre: false,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.TtodayToplamKayit = res.gelecekKonuklarSayac
              this.TtotalRows = res.gelecekKonuklar.length
            })
      }
    },
    perPageChange(newVal) {
      if (this.baslangic && this.bitis) {
        const veri = this.currentPage * newVal - newVal
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('onlineRezListAktive', {
              uzunluk: newVal,
              baslangic: veri,
              searchKey: this.filter,
              start: this.baslangic,
              end: this.bitis,
              filtre: true,
            })
            .then(res => {
              this.todayToplamKayit = res.arsivSayac
              this.totalRows = res.arsiv.length
              this.islem = false
              this.$refs.refreshCard.showLoading = false
            })
      } else {
        const veri = this.currentPage * newVal - newVal
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('onlineRezListAktive', {
              uzunluk: newVal,
              baslangic: veri,
              searchKey: this.filter,
            })
            .then(res => {
              this.islem = false
              this.$refs.refreshCard.showLoading = false
            })
      }
    },
    perPageChangeT(newVal) {
      if (this.baslangic && this.bitis) {
        const veri = this.currentPage * newVal - newVal
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('onlineRezListGelecek', {
              uzunluk: newVal,
              baslangic: veri,
              searchKey: this.filter,
              start: this.baslangic,
              end: this.bitis,
              filtre: true,
            })
            .then(res => {
              this.todayToplamKayit = res.arsivSayac
              this.totalRows = res.arsiv.length
              this.islem = false
              this.$refs.refreshCard.showLoading = false
            })
      } else {
        const veri = this.TcurrentPage * newVal - newVal
        this.$refs.refreshCard.showLoading = true
        this.$store
            .dispatch('onlineRezListGelecek', {
              uzunluk: newVal,
              baslangic: veri,
              searchKey: this.filterT,
            })
            .then(res => {
              this.TcurrentPage = 1
              this.islem = false
              this.$refs.refreshCard.showLoading = false
              this.TtodayToplamKayit = res.gelecekKonuklarSayac
              this.TtotalRows = res.gelecekKonuklar.length
            })
      }
    },
    refreshStop(cardName = 'refreshCard') {
      this.filtre = false
      this.perPage = 20
      this.TperPage = 20
      this.baslangic = ''
      this.bitis = ''
      this.filter = ''
      this.filterT = ''
      this.islem = true
      this.$store
          .dispatch('onlineRezListAktive', {
            uzunluk: this.perPage,
            baslangic: 0,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.$refs[cardName].showLoading = false
            this.islem = false
            this.todayToplamKayit = res.aktifKonuklarSayac
            this.totalRows = res.aktifKonuklar.length
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
      this.$store
          .dispatch('onlineRezListGelecek', {
            uzunluk: this.perPage,
            baslangic: 0,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.$refs[cardName].showLoading = false
            this.islem = false
            this.TtodayToplamKayit = res.gelecekKonuklarSayac
            this.TtotalRows = res.gelecekKonuklar.length
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    tarifeGet(tarifeID) {
      const veri = Object.values(this.$store.getters.tarife)
          .find(obj => obj.tarifeID == tarifeID)
      if (veri) {
        return veri.tarifeAdi
      }
    },
    rezGo(rezNo) {
      this.$router.push(`/rezervasyon/${rezNo}`)
    },
  },
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.custom-select-sm {
  max-width: 80px !important;
}

.perPageText {
  transform: translate(-69px, 0px);
  font-size: 15px;
}

.cardActions .card-body {
  min-height: 550px;
}

.mr-12 {
  margin-right: 12px !important;
  background-color: #2e83c9 !important;
}

.badge-primary {
  background-color: rgba(115, 103, 240, 0.12);
  color: #7367f0 !important;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #408dcc !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: space-between;
}

fieldset legend {
  transform: translate(29px, -2px) !important;
}

.araButton {
  border-color: #d8d6de !important;
}

.input-group:not(.bootstrap-touchspin):focus-within {
  box-shadow: none !important;
}

.input-group:not(.bootstrap-touchspin):focus-within .form-control,
.input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
  border-color: #d8d6de;
}

.disabledColor {
  border-color: #cbc1eb !important;
}

.card-headerr .col-8 {
  padding-right: 0 !important;
}

hr {
  border-top: 1px solid #e5e2ef;
}

.alignItemsCenter {
  align-items: center !important;
}

.searchGroup {
  width: 60% !important;
}

.myTitle {
  white-space: nowrap !important;
  font-size: 1.2vw !important;
}

.flatpickr-input[readonly], [dir] .flatpickr-input ~ .form-control[readonly], [dir] .flatpickr-human-friendly[readonly] {
  height: 30px;
}

.btn-outline-primary.disabled, [dir] .btn-outline-primary:disabled {
  height: 30px;
}

.disabledColor svg {
  transform: translate(0, -3px);
}

.w-100 {
  width: 100% !important;
}

</style>
